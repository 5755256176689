import React from "react"
import Services from "../components/Services"
import { Link } from "gatsby"
import { Parallax } from "react-parallax"
import bgImage from "../assets/images/Home-Top.jpg"
import servicesList from "../constants/service-list"
import Seo from "../components/utilities/Seo"

export default function Home() {
  return (
    <section className="home">
      <Seo title="サービス" />
      <Parallax
        bgImage={bgImage}
        bgImageAlt="shopify"
        strength={400}
        blur={{ min: 0, max: 1 }}
      >
        <div className="parallax-box">
          <div className="parallax-info">
            <h1>
              オンラインストアで <br></br> 全国のお客様へアプローチしませんか？
            </h1>

            <h2>Shopifyを使って自分のお店のオンライン販売をはじめよう。</h2>
            <p>ShopifyJapanBC認定のパートナーが構築の支援いたします。</p>
            <div>
              <Link to="/onlinestore/building-shopify" className="btn-primary">
                Shopify構築
              </Link>
            </div>
          </div>
        </div>
      </Parallax>
      <section className="services">
        <h1>サービス</h1>
        <Services servicesList={servicesList} />
      </section>
    </section>
  )
}
